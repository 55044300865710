<template>
  <!-- CONTENT -->
  <div class="container">
    <div class="d-flex">
      <PageTitle>Molecular Tests</PageTitle>
      <button class="btn btn-primary ml-auto" @click="toggleModal">Add Test</button>
    </div>
    <DxTreeList
      id="molecularTestGrid"
      :data-source="dataSource"
      :show-borders="true"
      :word-wrap-enabled="true"
      data-structure="tree"
      :expand-nodes-on-filtering="true"
      :rendery-async="true"
      :allowColumnReordering="true"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      no-data-text="No tests found"
      :columns="columns"
      ref="gridRef"
    >
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex justify-content-center">
          <icon-button icon="edit" class="btn pointer p-0 mr-2" @click="editTest(data.id)" />

          <icon-button
            icon="trash-alt"
            class="btn text-danger pointer p-0"
            @click="deleteTest(data.id)"
          />
        </div>
      </template>
    </DxTreeList>

    <Modal :status="isModalOpen" @close="toggleModal">
      <div class="container p-4">
        <h3>Add Molecular Test</h3>
        <form @submit="handleSubmit" class="form-grid">
          <SelectInput
            required
            label="Specimen"
            :items="specimenOptions"
            v-model="testForm.specimenId"
          />
          <SelectInput
            required
            label="Molecular Test"
            :dataSource="testOptions"
            v-model="testForm.cytMolecTestId"
          />
          <SelectInput
            label="Status"
            :items="statusOptions"
            v-model="testForm.cytMolecTestStatusId"
          />
          <div class="footer">
            <button class="btn btn-primary ml-auto" type="submit">Save</button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { SpecimensApi } from "@/services";
import { DxTreeList } from "devextreme-vue";
import { mapState } from "vuex";
import PageTitle from "../common/PageTitle.vue";
import Modal from "../common/Modal.vue";
import SelectInput from "../common/SelectInput.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { CytMolecTestStatusEnum, enumToDropDown } from "@/modules/enums";
import IconButton from "../common/IconButton.vue";

export default {
  components: { DxTreeList, PageTitle, Modal, SelectInput, IconButton },
  data() {
    return {
      isModalOpen: false,
      testOptions: cytologyService.createSearch(CytologyEntities.CytMolecTests),
      statusOptions: enumToDropDown(CytMolecTestStatusEnum),
      molecTests: {},
      testForm: {
        specimenId: null,
        cytMolecTestId: null,

        cytMolecTestStatusId: null
      }
    };
  },
  created() {
    if (this.specimenIds?.length) {
      this.loadSpecimenTests();
    }
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.caseDetails.specimens,
      user: state => state.currentUser
    }),
    columns() {
      return [
        {
          dataField: "cytMolecTest",
          caption: "Test"
        },
        { dataField: "seqNum", caption: "Seq Num" },
        { dataField: "requestedBy", caption: "Requested By" },
        { dataField: "requestedOn", caption: "Requested On", dataType: "date" },
        { dataField: "sentBy", caption: "Sent By" },
        { dataField: "sentOn", caption: "Sent On" },
        { dataField: "resultedBy", caption: "Resulted By" },
        { dataField: "resultedOn", caption: "Resulted On", dataType: "date" },
        { dataField: "cytMolecResult", caption: "Result" },
        { dataField: "cytMolecTestStatus", caption: "Status" },
        {
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    caseId() {
      return this.$route.params.caseId;
    },
    specimenIds() {
      return (this.specimens || [])?.map(specimen => specimen.id);
    },
    dataSource() {
      const keys = Object.keys(this.molecTests);
      if (keys.length) {
        return keys
          .map(key => this.molecTests[key].map(e => ({ ...e, specimenId: key })))
          .flat(Infinity);
      }
      return [];
    },
    specimenOptions() {
      return this.specimens.map(specimen => ({
        id: specimen.id,
        displayName: specimen.specimenOrder
      }));
    }
  },
  methods: {
    async deleteTest(id) {
      await SpecimensApi.deleteMolecularTest(id);
      this.loadSpecimenTests();
    },
    async editTest(id) {
      const details = await SpecimensApi.getMolecularTest(id);
      this.testForm = details;
      this.toggleModal();
    },
    getSpecimenTests(specimenId) {
      return SpecimensApi.getMolecularTests(specimenId);
    },
    async loadSpecimenTests() {
      const testsMap = {};
      await Promise.all(
        this.specimenIds.map(async id => {
          const tests = await this.getSpecimenTests(id);
          testsMap[id] = tests;
        })
      );
      this.molecTests = testsMap;
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    async handleSubmit(event) {
      event.preventDefault();
      console.log(this.testForm);
      const { specimenId, ...rest } = this.testForm;

      if ("id" in rest) {
        await SpecimensApi.updateMolecularTest(specimenId, rest);
      } else {
        const payload = {
          ...rest,
          requestedBy: this.user.displayName,
          requestedOn: new Date(),
          resultedBy: rest.cytMolecTestStatusId ? this.user.displayName : null,
          resultedOn: rest.cytMolecTestStatusId ? new Date() : null
        };
        await SpecimensApi.insertMolecularTest(specimenId, payload);
      }
      this.testForm = {
        specimenId: null,
        cytMolecTestId: null,
        requestedBy: null,
        requestedOn: null,
        sentBy: null,
        sentOn: null,
        resultedBy: null,
        resultedOn: null,
        cytMolecResultId: null,
        cytMolecTestStatusId: null
      };
      this.loadSpecimenTests();
      this.toggleModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  .footer {
    grid-column: span 2;
  }
}
</style>
